import React, {useContext, useMemo, useState} from "react";
import {BlankLink, Container, InfoTooltip, Loader, MaxBtn} from "../core/components";
import {FormContextWrapper} from "../core/form_context";
import {LicenceKind, LicenceKindLabelMap, LicenceKindMap, QualificationKind, QualificationKindLabelMap, QualificationKindMap, SectorTypeLicence, SectorTypeMap} from "../core/enums";
import styles from "./Licence.module.sass"
import {CheckboxInput, DateInput, EnumInput, FileUpload, LightContainer, MiniBtn, MyModal, SelectfieldInput, TextfieldInput} from "../core/input_fields";
import Status from "../core/status";
import {FaInfo, FaPlus} from "react-icons/all";
import {Redirect} from "react-router";
import {maxiDelete, maxiGet, maxiPost} from "../core/maxios";
import {encodeGetParams} from "../core/helpers";
import {FaTrashAlt} from "react-icons/fa";
import {UserContext} from "../user/UserContext";
import UserHistory from "../user/userHistory";
import {AddQualificationToLicence} from "./licenceQualifications";

const requiredPerLicence = {
    oeftC: {
        sectorSpecific: 40,
        crossSector: 20,
        minAge: 16,
    },
    oeftB: {
        minAge: 18,
        sectorSpecific: 150,
        minutesPerLesson: 60,
    },
    oeftA: {
        sectorSpecific: 90.75,
        minutesPerLesson: 60,
    }
}

function TableSelector({selections, value, setValue}) {
    return <table className={styles.licenceSelect}>
        <tr>
            {
                selections
                    .map(([v, label]) => <td className={value === v ? styles.licenceSelected : styles.licenceNotSelected} onClick={() => setValue(v)}>
                        {label}
                    </td>)
            }
        </tr>
    </table>
}


export function LicenceFormWrapper({match}) {
    const [qualifications, setQualifications] = useState(null);
    const [licence, setLicence] = useState(null);
    const [{error, loading}, setStatusVar] = useState({})
    const [licenceKind, setLicenceKind] = useState(match.params?.licenceKind);
    const [loadingNew, setLoadingNew] = useState(false)
    const [state, setState] = useState({licence: {sector: SectorTypeLicence.find(a => a.value === match.params?.sector)}})
    const [licenceCategory, setLicenceCategory] = useState(licenceKind === 'judge' ? 'judge' : null);

    const loadLicence = () => licenceKind && maxiGet("/licence/existent?" + encodeGetParams({licenceKind, sector: state.licence?.sector?.value}), {setStatusVar})
        .then(({licence, qualifications}) => {
            setLicence(licence)
            setQualifications(qualifications)
            setLoadingNew(false)
        })
    const updateState = (a, b, c) => {
        setState(old => ({...old, [a]: {...old[a], [b]: c}}))
    }

    useMemo(() => {
        if (licenceKind && state.licence?.sector?.value) {
            setLoadingNew(true)
            loadLicence()
        }
    }, [licenceKind, state.licence?.sector?.value])
    if (licenceKind && state.licence.sector?.value && (match.params?.licenceKind !== licenceKind || match.params?.sector !== state.licence.sector?.value)) {
        return <Redirect to={"/lizenz/beantragen/" + licenceKind + "/" + state.licence.sector?.value}/>
    } else if (match.params?.licenceKind !== licenceKind) {
        return <Redirect to={"/lizenz/beantragen/" + licenceKind}/>
    }


    return <Container name={"Turnsport-Austria-Lizenz beantragen"}>
        <Status type={"error"} text={error}/>
        Welche Art von Lizenz möchtest du beantragen / verlängern?
        <TableSelector selections={Object.entries({trainer: "Trainer*innen-Lizenz", judge: "Wertungsrichter*innen-Lizenz"})} value={licenceCategory} setValue={(v) => {
            setLicenceCategory(v);
            if (v === 'judge') {
                setLicenceKind("judge")
            }
        }}/>
        {
            licenceCategory === "trainer" && <>
                Welche Lizenzstufe möchtest du beantragen / verlängern?
                <TableSelector selections={Object.entries(LicenceKindLabelMap).filter(([k, v]) => k !== LicenceKindMap.judge)} value={licenceKind} setValue={setLicenceKind}/>
            </>
        }

        <FormContextWrapper value={{state, setState, updateState}}>
            <EnumInput type={"reactselect"} name="Sparte" tag={"licence_sector"}
                       selectives={SectorTypeLicence.filter(a => a.value !== SectorTypeMap.t10 && (!a.value.includes("tu") || licenceKind !== LicenceKindMap.oeftC) && (["gt", "gti"].includes(a.value) === false || licenceKind === LicenceKindMap.oeftC))}
            />
        </FormContextWrapper>

        <Loader loading={loadingNew && loading}/>
        {
            (!licence || !qualifications) && !loadingNew &&
            "Bitte zuerst die Art von Lizenz und die Sparte auswählen."
        }
        {
            licence && qualifications && !loadingNew &&
            <LicenceForm key={licence.ID} licenceArg={licence} qualifications={qualifications} setQualifications={setQualifications} autoSave={true} reload={loadLicence}/>
        }
    </Container>
}

export function LicenceForm({licenceArg, qualifications, setQualifications, autoSave, reload}) {
    const [{error, loading}, setStatusVar] = useState({})


    const [{error: error2, loading: loading2, success: success2}, setStatusVar2] = useState({})
    const {user} = useContext(UserContext);

    const [state, setState] = useState({licence: licenceArg})
    const {licence} = state;
    const save = (n) => maxiPost("/licence", n.licence, {setStatusVar});
    const updateState = (a, b, c) => {
        setState(old => {
            const n = ({...old, [a]: {...old[a], [b]: c}})
            if (autoSave) {
                save(n)
            }
            return n;
        });
    }

    const [qualificationsCount, setQualificationsCount] = useState({})

    const titleOfQualification = licence.initial ? "Ausbildung" : "Fort-/Weiterbildung";
    const requiredLessons = (licence.initial && requiredPerLicence[licence.kind.value]) || {sectorSpecific: 8, crossSector: 8}


    const submitLicence = () => window.setTimeout(() => {
        maxiPost("/licence/submit", licence, {setStatusVar: setStatusVar2}).then(a => setStatusVar2({success: a.message}))
    }, 200);


    return <>
        {/*<table className={styles.licenceSelect}>
            <tr>
                <td className={licence.initial === true ? styles.licenceSelected : styles.licenceNotSelected} onClick={() => setState(a => ({licence: {...a.licence, initial: true}}))}>Erstantrag</td>
                <td className={licence.initial === false ? styles.licenceSelected : styles.licenceNotSelected} onClick={() => setState(a => ({licence: {...a.licence, initial: false}}))}>Verlängerung</td>
            </tr>
        </table>*/}
        <Status type={"error"} text={error}/>
        <Status type={"success"} text={success2}/>


        {
            licence.kind && licence.initial !== null && !success2 && <>
                <h2>Antrag auf {licence.initial ? "Erstaustellung" : "Verlängerung"} der {LicenceKindLabelMap[licence.kind.value]}</h2>
                {
                    autoSave ? <p>
                        Dieser Antrag wird automatisch gespeichert und die Bearbeitung kann so zu jedem Zeitpunkt fortgesetzt werden.
                    </p> : (user?.role < 20 && <p>
                        <b>Sparte</b>: {licence.sector.label}
                    </p>)
                }
                <FormContextWrapper value={{state, setState, updateState}}>
                    {/*<SelectfieldInput name={"Art"} type="reactselect" tag={"licence_initial"} selectives={[{value: "ext", label: "Verlängerung"}, {value: "init", label: "Erstbeantragung"}]}/>*/}
                    {
                        user?.role >= 20 && <>
                            <EnumInput type={"reactselect"} name="Stufe" tag={"licence_kind"} width={390} selectives={LicenceKind}/>
                            &nbsp; &nbsp;
                            <EnumInput type={"reactselect"} name="Sparte" tag={"licence_sector"}
                                       selectives={SectorTypeLicence.filter(a => a.value !== SectorTypeMap.t10 && (!a.value.includes("tu") || licence?.kind?.value !== LicenceKindMap.oeftC) && (["gt", "gti"].includes(a.value) === false || licence?.kind?.value === LicenceKindMap.oeftC))}/>
                            &nbsp; &nbsp;
                            {
                                licence.kind.value !== LicenceKindMap.judge && <>
                                    <CheckboxInput name={"Übergangslizenz"} tag={"licence_noQualifications"}/><br/>
                                </>
                            }
                            <DateInput name={"Gültig bis überschreiben"} tag={"licence_validTill"} maxDate={new Date(2100, 1, 1)}/>
                        </>
                    }
                    {
                        licence.initial && <FileUploads {...{licence, setState, updateState}}/>
                    }
                </FormContextWrapper>
                {
                    !autoSave && <MaxBtn onClick={() => maxiPost("/licence", state.licence, {setStatusVar}).then(reload)}>Speichern</MaxBtn>
                }
                {
                    !licence.noQualifications && <LightContainer name={titleOfQualification + "en"}>
                        {
                            !licence.initial && <p>Gesamt hast du <br/>
                                spartenspezifisch:&nbsp;
                                {Math.round((Object.values(qualificationsCount).filter(a => a.kind === QualificationKindMap.sectorSpecific).reduce((count, current) => (current.count || 0) + count, 0) || 0)
                                    / (requiredLessons?.minutesPerLesson || 45) * 100) / 100} UE&nbsp;
                                ({requiredLessons.sectorSpecific || 0} UE erforderlich),<br/>

                                spartenübergreifend:&nbsp;
                                {Math.round((Object.values(qualificationsCount).filter(a => a.kind === QualificationKindMap.crossSector).reduce((count, current) => (current.count || 0) + count, 0) || 0)
                                    / (requiredLessons?.minutesPerLesson || 45) * 100) / 100} UE&nbsp;
                                ({requiredLessons.crossSector || 0} UE erforderlich).<br/>
                            </p>
                        }
                        {
                            licence.initial && ({
                                [LicenceKindMap.oeftC]: <p>
                                    Anerkannte Ausbildungen:
                                    <ul>
                                        <li>ÖFT-C-Trainer*innen-Ausbildung (ehem. ÖFT-Übungsleiter-Ausbildung)*</li>
                                        <li> Universitätsstudium Sportwissenschaft/Sport Lehramt inkl. zwei Fachsemestern*</li>
                                        <li> FIG Level 1-Ausbildung*</li>
                                    </ul>
                                    *der jeweiligen Fachsparte von Turnsport Austria
                                </p>,
                                [LicenceKindMap.oeftB]: <p>
                                    Anerkannte Ausbildungen:
                                    <ul>
                                        <li>Staatliche Instruktor*innen-Ausbildung*</li>
                                        <li>FIG Level 2-Ausbildung*</li>
                                        <li>EG-Level 1+2-Ausbildung*</li>
                                    </ul>
                                    *der jeweiligen Fachsparte von Turnsport Austria
                                </p>,
                                [LicenceKindMap.oeftA]: <p>
                                    Anerkannte Ausbildungen:
                                    <ul>
                                        <li>Staatliche Trainer*innen-Ausbildung*</li>
                                        <li>FIG Level 3-Ausbildung*</li>
                                        <li>EG-Level 3-Ausbildung*</li>
                                        <li>internationales Universitätsstudium Sportwissenschaft + Trainer*innen-Ausbildung*</li>
                                    </ul>
                                    *der jeweiligen Fachsparte von Turnsport Austria
                                </p>,
                            })[licence.kind.value]
                        }


                        {
                            qualifications.map((qualification, i) => <Qualification key={qualification.ID ?? "new" + i} {...{qualification, licence, i, titleOfQualification, setQualificationsCount, autoSave, updateState, reload}}/>)
                        }

                        <p>
                            Hier können und sollen weitere Aus-, Fort- und Weiterbildungen (bei Art <b>{QualificationKindLabelMap.nonRelevant}</b> auswählen) hochgeladen werden.
                        </p>

                        <MaxBtn onClick={() => {
                            setQualifications([...qualifications, {
                                licence_ID: licence.ID,
                                minutesPerLesson: 45,
                            }])
                        }}><FaPlus/> {titleOfQualification}{titleOfQualification === "Ausbildung" ? "s-Teil" : ""} hinzufügen</MaxBtn>
                        &nbsp;
                        <AddQualificationToLicence {...{licence, titleOfQualification, reload}}/>
                    </LightContainer>
                }
                <br/>
                <Status type={"error"} text={error2}/>
                <Status type={"success"} text={success2}/>

                <MaxBtn style={{padding: "12px 16px"}} onClick={() => {
                    if (autoSave) {
                        !loading2 && submitLicence()
                    } else {
                        !loading2 && save(state).then(() => submitLicence())
                    }
                }}>
                    Antrag abschicken
                </MaxBtn>

                <Loader loading={loading2}/>
                <ShowHistoryIfAdmin entity_ID={licence.ID} type={"licence"}/>
            </>
        }

    </>
}

function ShowHistoryIfAdmin({entity_ID, type, wrapper}) {
    const context = useContext(UserContext);

    if (!(context?.user?.role > 20)) {
        return null
    }
    if (wrapper) {
        return wrapper(<UserHistory personType={type} userID={entity_ID}/>)
    }
    return <UserHistory personType={type} userID={entity_ID}/>
}

const now = new Date();


function FileUploads({licence, updateState}) {
    const [{error, loading}, setStatusVar] = useState({})
    /*a => {
        if (a.error) {
            return setStatusVar(b => ({...b, error: a.error}))
        }
        const key = Object.keys(a).find(a => !["error", "status"].includes(a))
        const s = key.split("_")
        updateState(s[0], s[1], a[key])
        setState(b => ({...b, [key]: a[key]}))
    }

     */
    const MyFileUpload = c => <FileUpload width={320} updateState={updateState} {...c}/>
    return <LightContainer name={"Bescheinigungen"}>
        <p>
            PDF oder JPG mit maximal 5 mb
        </p>
        <MyFileUpload
            name={<>Behördlicher Identitäts-Nachweis <InfoTooltip>Reisepass, Führerschein oder Personalausweis</InfoTooltip></>}
            tag={"licence_identity"}/>
        {
            licence.kind.value !== LicenceKindMap.judge && <>
                <MyFileUpload
                    name={<>Strafregister-Bescheinigung <InfoTooltip>Darf zum Zeitpunkt des Antrags nicht älter als drei Monate sein.</InfoTooltip></>}
                    tag={"licence_criminalRegister"}/>
                <MyFileUpload
                    name={<>Strafregister-Bescheinigung der Kinder- und Jugendfürsorge <InfoTooltip>Darf zum Zeitpunkt des Antrags nicht älter als drei Monate sein.</InfoTooltip></>}
                    tag={"licence_childCriminalRegister"}/>
            </>
        }

        <MyFileUpload
            name={<>Zustimmungserklärung Einhaltung Trainer-Verhaltenskodex&nbsp;
                <InfoTooltip>
                    Unterzeichnete Zustimmungserklärung (Original oder Scan) zur vollumfänglichen unwiderrufbaren Einhaltung des Trainer-Verhaltenskodex von Sport Austria<br/>
                    <br/>
                    <BlankLink>https://www.turnsport.at/de/service/downloads/docdown-allgemeine-oeft-bestimmungen-RPflzsKOdMefI</BlankLink>
                </InfoTooltip></>}
            tag={"licence_kodex"}/>

        {
            licence.noQualificationsAllowed && <p style={{marginBottom: 0}}>
                <CheckboxInput tag={"licence_noQualifications"} change={(a) => updateState("licence", "noQualifications", a.target.checked)} noLabel name={""}/>
                &nbsp;
                Ich habe noch keine Turnsport-spezifische Ausbildung abgeschlossen und möchte trotzdem meine Turnsport-Austria-Übergangslizenz beantragen.
            </p>
        }
    </LightContainer>
}


const lockedTitles = [
    "Turnsport-Austria-Trainer-Basismodul",
    "Turnsport-Austria-Trainer-Spezialmodul",
    "Instruktor-Ausbildung",
    "Trainer-Grundkurs",
    "Trainer-Spezialkurs",
]


function Qualification({qualification, licence, i, titleOfQualification, setQualificationsCount, reload, autoSave}) {
    const [state, setState] = useState({qualification});
    const [{error}, setStatusVar] = useState({});
    const [timer, setTimer] = useState(null);

    const save = (c) => maxiPost("/licence/qualification", {...state.qualification, ...c}, {setStatusVar}).then(({created}) => {
        if (created) {
            reload && reload()
        }
    });

    const setQualificationsCountFromState = (_, b, c) => {
        const count = (b === "lessons" ? c[b] : state.qualification.lessons) * (b === "minutesPerLesson" ? c[b] : state.qualification.minutesPerLesson || 45);
        setQualificationsCount(a => ({...a, [i]: {count, kind: b === "kind" ? c[b].value : state.qualification.kind?.value}}))

        if (b && autoSave) {
            timer && clearTimeout(timer);
            const t = window.setTimeout(() => {
                save(c)
            }, 100)
            setTimer(t);
        }
    };
    const updateState = (a, b, c) => {
        setState(d => ({...d, [a]: {...d[a], [b]: c}}))
        setQualificationsCountFromState(a, b, {[b]: c})
    }


    const isLocked = (i === 0 || i === 1) && licence.initial && lockedTitles.includes(state.qualification.title);

    useMemo(() => setQualificationsCountFromState(null, null, null), [])
    return <div className={styles.qualification}>
        <h3>
            {
                qualification.ID && <div style={{float: "right"}}>

                    <ShowHistoryIfAdmin wrapper={children => <MyModal trigger={<><MaxBtn><FaInfo/></MaxBtn>&nbsp;&nbsp;</>}><Container name={"Protokoll"}>{children}</Container></MyModal>} entity_ID={qualification.ID} type={"qualification"}/>

                    <MaxBtn onClick={() => maxiDelete("/licence/qualification/" + qualification.ID, {setStatusVar}).then(reload)}>
                        <FaTrashAlt/>
                    </MaxBtn>
                </div>
            }
            {titleOfQualification}{titleOfQualification === "Ausbildung" ? "s-Teil" : ""} {i + 1}
        </h3>
        <Status type={"error"} text={error}/>
        <FormContextWrapper value={{state, setState, updateState}}>
            {
                !isLocked && <><EnumInput name={"Art"} tag={"qualification_kind"} type="reactselect" selectives={QualificationKind}/><br/></>
            }

            {
                licence.kind.value === LicenceKindMap.judge && <>
                    <TextfieldInput readOnly={isLocked} name={"Kategorie"} tag={"qualification_category"} ph={""} onBlur={() => setQualificationsCountFromState(null, true, null)}/>
                </>
            }
            <TextfieldInput readOnly={isLocked} name={"Bezeichnung"} tag={"qualification_title"} ph={"Name"} onBlur={() => setQualificationsCountFromState(null, true, null)}/>
            <p><b>Zertifikate / Nachweise für die erfolgreiche Absolvierung als PDF oder JPEG (max. 5 mb und 3 Dateien):</b></p>
            {
                !state.qualification.noCertificateRequired && <FileUpload name={"Datei"} tag={"qualification_certificate1"} updateState={updateState}/>
            }
            {
                /*!!state.qualification.certificate1 &&
                <FileUpload name={"Datei 2 (optional)"} tag={"qualification_certificate2"} updateState={updateState}/>
            }
            {
                !!state.qualification.certificate2 &&
                <FileUpload name={"Datei 3 (optional)"} tag={"qualification_certificate3"} updateState={updateState}/>*/
            }
            {
                !licence.initial && <>
                    <TextfieldInput name={"Umfang"} tag={"qualification_lessons"} ph={8} style={{width: "100px"}} onBlur={() => setQualificationsCountFromState(null, true, null)}/>&nbsp;
                    UE <InfoTooltip>Unterrichtseinheiten</InfoTooltip>&nbsp;
                    zu je <SelectfieldInput tag={"qualification_minutesPerLesson"} type={"reactselect"} inline width={"100px"} noLabel selectives={[45, 50, 60].map(a => ({value: a, label: a}))}/> min
                    <br/>
                </>
            }
            <DateInput name={"Abschlussdatum"} tag={"qualification_timeFinished"}/>
            <br/>
            <TextfieldInput name={titleOfQualification + "s-Anbieter"} tag={"qualification_location"} ph={"Turnsport-Austria-Akademie"} onBlur={() => setQualificationsCountFromState(null, true, null)}/>

        </FormContextWrapper>
        {
            !autoSave && <MaxBtn onClick={() => save({})}>Speichern</MaxBtn>
        }
    </div>
}
